<template>
  <el-dialog
    append-to-body
    width="400px"
    title="分配配送员"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    >
      <el-form
        class="staff-from"
        v-loading="loading"
        element-loading-text="加载中…"
        ref='elFormDom'
        label-width='80px'
        label-position='right'
        :model='form.data'
        :rules='form.rules'
        :size="subUnitSize"
      >
        <el-form-item label="配送员" prop="rider_id">
          <el-select v-model="form.data.rider_id" placeholder="请选择" class="width100">
            <el-option
              v-for="item in riderArr"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
     
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click='toggle(false)'>取消</el-button>
        <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click='confirm'>确定</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import common from '@/util'
  import { getStorage } from '@/storage'
  export default {
    name: 'OrderRiderEdit',
    data() {
      return {
        token: getStorage('token'),
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            id: '',
            rider_id: '',
          },
          rules: {
            rider_id: [{ required: true, message:'请选择配送员', trigger: ['change','blur']}],
          }
        },
        riderArr: [],
      }
    },
    methods: {
      getDetail(row) {
        common.deepClone(this.form.data, row)
        this.form.data.rider_id = row.rider_id == 0 ? '' : row.rider_id;
        this.getRider(); //查询配送员
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 提交
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
            let _param = {
              id: this.form.data.id,
              rider_id: this.form.data.rider_id,
            }
            this.form.loading = true;
            this.$http.post('/admin/order/dispatchRider', _param).then(res => {
              if(res.code === 1) {
                this.$message.success('操作成功！')
                this.toggle(false);
                this.$emit('refresh')
              }
            }).finally(() => {
              this.form.loading = false;
            })
          }
        })
      },
      getRider() {
        this.$http.post('/admin/admin/riderList',{page:1,count:1000}).then(res => {
          if(res.code == 1) {
            this.riderArr = res.data;
          }
        })
      }
    }
  }
</script>

<style scoped>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  /* .idPhoto .avatar{
    width: 100% !important;
  } */
  .idPhoto .avatar-uploader-icon {
    width: 178px !important;
    height: 178px !important;
    line-height: 178px !important;
    border: 1px dashed #d9d9d9;
  }
  .onBtn-large {
    display: flex;
    justify-content: center;
  }
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-dialog',{attrs:{"title":"复制文字","visible":_vm.visible,"top":"2vh","width":"600px","before-close":_vm.handleClose,"close-on-click-modal":false,"append-to-body":""},on:{"update:visible":function($event){_vm.visible=$event}}},[_c('el-form',{ref:"elFormDom",attrs:{"model":_vm.form.data,"rules":_vm.form.rules,"label-position":"top"}},[_c('el-form-item',{attrs:{"label":"粘贴内容","prop":"text"}},[_c('el-input',{attrs:{"type":"textarea","autosize":{ minRows: 2, maxRows: 8},"placeholder":"格式示例：小青菜5斤，白萝卜3斤，土豆丝3斤，水果西瓜20 斤"},model:{value:(_vm.form.data.text),callback:function ($$v) {_vm.$set(_vm.form.data, "text", $$v)},expression:"form.data.text"}})],1),_c('div',{staticStyle:{"text-align":"center"}},[_c('el-button',{attrs:{"type":"warning","size":"small","loading":_vm.form.loading},on:{"click":_vm.recognizeFun}},[_vm._v("开始识别")])],1)],1),(_vm.tableData.length > 0)?[_c('el-divider',{attrs:{"content-position":"left"}},[_vm._v("识别内容共"+_vm._s(_vm.tableData.length)+"项")]),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"border":"","size":"mini","max-height":"300"}},[_c('el-table-column',{attrs:{"width":"50"},scopedSlots:_vm._u([{key:"header",fn:function(ref){return [_c('i',{staticClass:"el-icon-s-fold"})]}},{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [_c('el-popconfirm',{attrs:{"title":"确认删除此项？"},on:{"confirm":function($event){return _vm.onMinus(row,$index)}}},[_c('el-button',{staticStyle:{"width":"22px","height":"18px","padding":"0"},attrs:{"slot":"reference","title":"删除","type":"danger","plain":""},slot:"reference"},[_vm._v("-")])],1)]}}],null,false,1101038166)}),_c('el-table-column',{attrs:{"type":"index","label":"序号","width":"60"}}),_c('el-table-column',{attrs:{"prop":"goods_name","label":"商品名称","width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"size":"mini","clearable":""},model:{value:(row.goods_name),callback:function ($$v) {_vm.$set(row, "goods_name", $$v)},expression:"row.goods_name"}})]}}],null,false,2724906794)}),_c('el-table-column',{attrs:{"prop":"num","label":"数量"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"size":"mini","clearable":""},model:{value:(row.num),callback:function ($$v) {_vm.$set(row, "num", $$v)},expression:"row.num"}})]}}],null,false,1354599924)}),_c('el-table-column',{attrs:{"prop":"unit","label":"单位"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"size":"mini","clearable":""},model:{value:(row.unit),callback:function ($$v) {_vm.$set(row, "unit", $$v)},expression:"row.unit"}})]}}],null,false,4226215300)})],1),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.handleClose}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary","loading":_vm.form.loading},on:{"click":_vm.uploadSubmit}},[_vm._v("确 定")])],1)]:_vm._e()],2),_c('el-dialog',{attrs:{"title":"反馈信息","visible":_vm.errorDialogVisible,"width":"500px","center":"","before-close":_vm.errorClose,"append-to-body":""},on:{"update:visible":function($event){_vm.errorDialogVisible=$event}}},[_c('div',{staticStyle:{"font-weight":"bold","margin-bottom":"10px"}},[_vm._v("导入成功："+_vm._s(_vm.templateInfo.success)+" 条")]),_c('div',[_vm._v("以下商品信息导入失败：")]),(_vm.templateInfo.errors.length > 0)?_c('div',{staticClass:"errorBox"},_vm._l((_vm.templateInfo.errors),function(item,index){return _c('div',{key:index,staticClass:"err-list"},[_vm._v(_vm._s(index +1)+"、"+_vm._s(item))])}),0):_vm._e(),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"plain":""},on:{"click":_vm.errorExp}},[_vm._v("下载反馈信息")]),_c('el-button',{on:{"click":_vm.errorClose}},[_vm._v("关闭")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <el-dialog title="复制文字" :visible.sync="visible" top="2vh" width="600px" :before-close="handleClose" :close-on-click-modal="false"  append-to-body>
      <el-form :model="form.data" ref="elFormDom" :rules="form.rules" label-position="top">
        <el-form-item label="粘贴内容" prop="text">
          <el-input
            v-model="form.data.text"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 8}"
            placeholder="格式示例：小青菜5斤，白萝卜3斤，土豆丝3斤，水果西瓜20 斤">
          </el-input>
        </el-form-item>
        <div style="text-align:center;">
          <el-button type="warning" size="small" @click="recognizeFun" :loading="form.loading" >开始识别</el-button>
        </div>
      </el-form>
      <template v-if="tableData.length > 0">
        <el-divider content-position="left">识别内容共{{tableData.length}}项</el-divider>
        <el-table
          :data="tableData"
          border
          size="mini"
          max-height="300"
          style="width: 100%">
          <el-table-column
            width="50">
            <template slot="header" slot-scope="{}">
              <i class="el-icon-s-fold"></i>
            </template>
            <template slot-scope="{row, $index}">
              <el-popconfirm title="确认删除此项？" @confirm="onMinus(row,$index)">
                <el-button title="删除" type="danger" slot="reference" plain style="width: 22px;height: 18px;padding: 0;">-</el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
          <el-table-column
            type="index"
            label="序号"
            width="60">
          </el-table-column>
          <el-table-column
            prop="goods_name"
            label="商品名称"
            width="180">
            <template slot-scope="{row}">
              <el-input v-model="row.goods_name" size="mini" clearable></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="num"
            label="数量">
            <template slot-scope="{row}">
              <el-input v-model="row.num" size="mini" clearable></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="unit"
            label="单位">
            <template slot-scope="{row}">
              <el-input v-model="row.unit" size="mini" clearable></el-input>
            </template>
          </el-table-column>
        </el-table>
        <div slot="footer" class="dialog-footer">
          <el-button @click="handleClose">取 消</el-button>
          <el-button type="primary" @click="uploadSubmit" :loading="form.loading">确 定</el-button>
        </div>
      </template>
    </el-dialog>

    <!-- 导入异常弹窗 -->
    <el-dialog
      title="反馈信息"
      :visible.sync="errorDialogVisible"
      width="500px"
      center
      :before-close="errorClose"
      append-to-body
      >
        <div style="font-weight: bold;margin-bottom:10px;">导入成功：{{templateInfo.success}} 条</div>
        <div>以下商品信息导入失败：</div>
        <div v-if="templateInfo.errors.length > 0" class="errorBox">
          <div class="err-list" v-for="(item, index) in templateInfo.errors" :key="index">{{index +1}}、{{item}}</div>
        </div>
      <span slot="footer" class="dialog-footer">
        <el-button plain @click="errorExp">下载反馈信息</el-button>
        <el-button @click="errorClose">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'ImportText',
  data() {
    return {
      visible: false,
      objData: {
        merchant_id: '', // 商户id
      },
      tableData: [],
      form: {
        loading: false,
        data: {
          text: '',
        }
      },
      // 导入后提示
      errorDialogVisible: false,  // 导入异常弹窗
      templateInfo: {
        success: 0,
        errors: [],
      },
      listArr: [],
    }
  },
  methods: {
    getDetail(obj) {
      this.visible = true;
      this.objData = obj;
    },
    reset(done) {
      this.isChange = false;
      done && done()
    },
    toggle(show) {
      this.visible = show
      !show && this.reset()
    },
    // 开始识别
    recognizeFun() {
      if(!this.form.data.text) {
        this.$message.warning("请输入识别内容")
        return
      }
      this.form.loading = true;
      this.$http.post('/admin/order/textRecognize', {merchant_id:this.objData.merchant_id, text:this.form.data.text}).then(res => {
        if(res.code == 1) {
          this.tableData = res.data.list;
        }
      }).finally(() => {
        this.form.loading = false;
      })
    },
    // 识别转换商品
    uploadSubmit() {
      let _params = {
        merchant_id: this.objData.merchant_id, // 商户id
        spec: this.tableData, // 识别出来的商品
      }
      this.$http.post('/admin/order/importRecognize', _params).then(res => {
        if(res.code == 1) {
          // 导入失败的就弹窗显示错误信息并能下载文本
          if(res.data.errors.length > 0) {
            this.errorDialogVisible = true; // 打开反馈错误信息弹窗
            this.templateInfo.errors = res.data.errors; //上传失败数据
            this.templateInfo.success = res.data.list.length; // 上传成功条数
          } else {
            this.$message.success('添加成功！')
          }
          this.handleClose(); // 关闭弹窗
          this.listArr = res.data.list.map(v => {
            return {
              good_id: v.goods_id, //商品id
              good_name: v.goods_name, // 商品名称
              image: v.images, // 商品图片
              num: v.num, // 下单数量
              remark: v.remark, // 备注
              spec: v.spec, // 规格数组
              name: v.spec.length == 1 ? v.spec[0].name : '', // 规格名称
              id: v.spec.length == 1 ? v.spec[0].id : 0, // 规格id
              price: v.spec.length == 1 ? v.spec[0].price : '',//售价
              unit_name: v.spec.length == 1 ? v.spec[0].unit_name : '', //单位
              spec_amount: v.spec.length == 1 ? v.spec[0].refer_price : '', // 参考价
              type:v.spec.length > 1 ? 2 : 1, // 1文本，2：下拉框
            }
          })
          console.log(this.listArr,"this.listArr")
          // 将导入成功的数据传递给父组件
          this.$emit('sendgoodslist',this.listArr)
        }
      }).finally(() => {
        this.form.loading = false;
      })
    },
    errorExp() {
      const text = this.templateInfo.errors;
      const blob = new Blob([text], {type:'text/plain'});
      // 创建a标签元素
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob)
      link.download ='file.txt';
      // 添加到页面上进行点击下装
      document.body.appendChild(link);
      link.click();
      // 移除临时生成的a标签无素
      document.body.removeChild(link);
    },
    // 关闭导入弹窗
    handleClose() {
      this.toggle(false)
      this.tableData = [];
      this.form.data.text = '';
    },
    errorClose() {
      this.errorDialogVisible = false;
    },
    // 减
    onMinus(row,index) {
      this.tableData.forEach((v,i) => {
        if(index == i) {
          this.tableData.splice(i, 1)
        }
      })
    },
  }
}
</script>
<style scoped>
::v-deep .el-form--label-top .el-form-item__label {
  padding: 0 !important;
}
.err-list {
  margin-top: 10px;
}
</style>
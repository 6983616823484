<template>
  <div>
    <el-dialog title="图片识别" :visible.sync="visible" top="2vh" width="600px"  :before-close="handleClose" :close-on-click-modal="false" append-to-body>
      <el-form :model="form" ref="elFormDom" :rules="form.rules" label-position="top">
        <el-alert
          title="温馨提示：由于图片清晰度、字迹、格式不同可能识别率不同，建议按照示例要求上传图片，并仔细核对识别内容"
          type="warning"
          :closable="false">
        </el-alert>
        <el-row>
          <el-col :span="12">
            <el-form-item label="上传图片" prop="voucher">
              <el-upload
                action="/api/common/qiniu/upload"
                :limit="1"
                list-type="picture-card"
                :file-list="fileList"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                :http-request="httpRequestBack"
                class="imgWrap"
                style="width: 148px; height: 148px;overflow:hidden;"
                accept="image/jpeg, image/png, image/gif"
              >
                <img v-if="form.data.voucher" :src="form.data.voucher" style="object-fit: cover;width:100%;" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <el-dialog :visible.sync="dialogVisibleUrl" append-to-body>
                <img width="100%" :src="dialogImageUrl" alt="" />
              </el-dialog>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="示例">
              <el-image 
                style="width: 200px; height: 148px"
                :src="require('@/assets/img/recognizeImg.png')"
                :preview-src-list="[require('@/assets/img/recognizeImg.png')]">
              </el-image>
            </el-form-item>
          </el-col>
          <el-col :span="24" style="text-align:center;">
            <el-button type="warning" size="small" @click="recognizeFun" :loading="form.loading" >开始识别</el-button>
          </el-col>
        </el-row>
      </el-form>
      <template v-if="tableData.length > 0">
        <el-divider content-position="left">识别内容共{{tableData.length}}项</el-divider>
        <el-table
          :data="tableData"
          border
          size="mini"
          max-height="300"
          style="width: 100%">
          <el-table-column
            width="50">
            <template slot="header" slot-scope="{}">
              <i class="el-icon-s-fold"></i>
            </template>
            <template slot-scope="{row, $index}">
              <el-popconfirm title="确认删除此项？" @confirm="onMinus(row,$index)">
                <el-button title="删除" type="danger" slot="reference" plain style="width: 22px;height: 18px;padding: 0;">-</el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
          <el-table-column
            type="index"
            label="序号"
            width="60">
          </el-table-column>
          <el-table-column
            prop="goods_name"
            label="商品名称"
            width="180">
            <template slot-scope="{row}">
              <el-input v-model="row.goods_name" size="mini" clearable></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="num"
            label="数量">
            <template slot-scope="{row}">
              <el-input v-model="row.num" size="mini" clearable></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="unit"
            label="单位">
            <template slot-scope="{row}">
              <el-input v-model="row.unit" size="mini" clearable></el-input>
            </template>
          </el-table-column>
        </el-table>
        <div slot="footer" class="dialog-footer">
          <el-button @click="handleClose">取 消</el-button>
          <el-button type="primary" @click="uploadSubmit" :loading="form.loading">确 定</el-button>
        </div>
      </template>
    </el-dialog>

    <!-- 导入异常弹窗 -->
    <el-dialog
      title="反馈信息"
      :visible.sync="errorDialogVisible"
      width="500px"
      center
      :before-close="errorClose"
      append-to-body
      >
        <div style="font-weight: bold;margin-bottom:10px;">导入成功：{{templateInfo.success}} 条</div>
        <div>以下商品信息导入失败：</div>
        <div v-if="templateInfo.errors.length > 0" class="errorBox">
          <div class="err-list" v-for="(item, index) in templateInfo.errors" :key="index">{{index +1}}、{{item}}</div>
        </div>
      <span slot="footer" class="dialog-footer">
        <el-button plain @click="errorExp">下载反馈信息</el-button>
        <el-button @click="errorClose">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'ImportImage',
  data() {
    return {
      visible: false,
      objData: {
        sampleFile: '', // 样表
        merchant_id: '', // 商户id
      },
      tableData: [],
      form: {
        loading: false,
        data: {

        }
      },
      curfile: '',
      // 导入后提示
      errorDialogVisible: false,  // 导入异常弹窗
      templateInfo: {
        success: 0,
        errors: [],
      },
      listArr: [],
      fileList: [],
      dialogImageUrl: '',
      dialogVisibleUrl: false,
    }
  },
  methods: {
    getDetail(obj) {
      this.visible = true;
      this.objData = obj;
    },
    reset(done) {
      this.isChange = false;
      done && done()
    },
    toggle(show) {
      this.visible = show
      !show && this.reset()
    },
    httpRequestBack(file) {
      this.curfile = file.file;
    },
    // 开始识别
    recognizeFun() {
      console.log(this.curfile,"this.curfile")
      console.log(Object.keys(this.curfile).length === 0)
      if(Object.keys(this.curfile).length === 0) {
        this.$message.warning("请上传图片")
        return
      }
      let formData = new FormData();
      formData.append('file', this.curfile);
      this.form.loading = true;
      this.$http.post('/admin/order/recognize', {formData, type:'upload'}).then(res => {
        if(res.code == 1) {
          this.tableData = res.data.list;
        }
      }).finally(() => {
        this.form.loading = false;
      })
    },
    // 识别转换商品
    uploadSubmit() {
      let _params = {
        merchant_id: this.objData.merchant_id, // 商户id
        spec: this.tableData, // 识别出来的商品
      }
      this.$http.post('/admin/order/importRecognize', _params).then(res => {
        if(res.code == 1) {
          // 导入失败的就弹窗显示错误信息并能下载文本
          if(res.data.errors.length > 0) {
            this.errorDialogVisible = true; // 打开反馈错误信息弹窗
            this.templateInfo.errors = res.data.errors; //上传失败数据
            this.templateInfo.success = res.data.list.length; // 上传成功条数
          } else {
            this.$message.success('添加成功！')
          }
          this.handleClose(); // 关闭弹窗
          this.listArr = res.data.list.map(v => {
            return {
              good_id: v.goods_id, //商品id
              good_name: v.goods_name, // 商品名称
              image: v.images, // 商品图片
              num: v.num, // 下单数量
              remark: v.remark, // 备注
              spec: v.spec, // 规格数组
              name: v.spec.length == 1 ? v.spec[0].name : '', // 规格名称
              id: v.spec.length == 1 ? v.spec[0].id : 0, // 规格id
              price: v.spec.length == 1 ? v.spec[0].price : '',//售价
              unit_name: v.spec.length == 1 ? v.spec[0].unit_name : '', //单位
              spec_amount: v.spec.length == 1 ? v.spec[0].refer_price : '', // 参考价
              type:v.spec.length > 1 ? 2 : 1, // 1文本，2：下拉框
            }
          })
          console.log(this.listArr,"this.listArr")
          // 将导入成功的数据传递给父组件
          this.$emit('sendgoodslist',this.listArr)
        }
      }).finally(() => {
        this.form.loading = false;
      })
    },
    errorExp() {
      const text = this.templateInfo.errors;
      const blob = new Blob([text], {type:'text/plain'});
      // 创建a标签元素
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob)
      link.download ='file.txt';
      // 添加到页面上进行点击下装
      document.body.appendChild(link);
      link.click();
      // 移除临时生成的a标签无素
      document.body.removeChild(link);
    },
    // 关闭导入弹窗
    handleClose() {
      this.toggle(false)
      this.fileList = [];
      this.curfile = '';
      this.tableData = [];
    },
    errorClose() {
      this.errorDialogVisible = false;
    },
    handleRemove(file, fileList) {
      this.curfile = {};
    },
    // 图片上传之前
    beforeAvatarUpload(file) {
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isLt20M) {
        this.$message.warning('上传图片大小不能超过 20MB!');
      }
      return isLt20M;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisibleUrl = true;
    },
    // 减
    onMinus(row,index) {
      this.tableData.forEach((v,i) => {
        if(index == i) {
          this.tableData.splice(i, 1)
        }
      })
    },
  }
}
</script>
<style scoped>
::v-deep .el-form--label-top .el-form-item__label {
  padding: 0 !important;
}
.err-list {
  margin-top: 10px;
}
</style>